import {createRouter, createWebHashHistory} from 'vue-router';
import App from '../App.vue';
import jwt from 'jsonwebtoken';
import store from '@/store'

store.getters.config

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('../components/Dashboard/Dashboard.vue')
            },
            {
                path: '/ventas-online',
                name: 'ventas-online',
                component: () => import('../components/Sales/SalesOnline.vue')
            },
            {
                path: '/listas-online',
                name: 'listas-online',
                component: () => import('../components/Sales/ListsOnline.vue')
            },
            {
                path: '/tickets-ganadores',
                name: 'tickets-ganadores',
                component: () => import('../components/Tickets/Winners.vue')
            },
            {
                path: '/tickets-pagados',
                name: 'tickets-pagados',
                component: () => import('../components/Tickets/Paid.vue')
            },
            {
                path: '/tickets-no-pagados',
                name: 'tickets-no-pagados',
                component: () => import('../components/Tickets/Unpaid.vue')
            },
            {
                path: '/tickets-anulados',
                name: 'tickets-anulados',
                component: () => import('../components/Tickets/Cancelled.vue')
            },
            {
                path: '/tickets-vendidos',
                name: 'tickets-vendidos',
                component: () => import('../components/Tickets/Sold.vue')
            },
            // Cancel
            {
                path: '/tickets-anular',
                name: 'tickets-anular',
                component: () => import('../components/Tickets/Cancel.vue')
            },
            // Anular
            {
                path: '/tickets-anular-1',
                name: 'tickets-anular-1',
                component: () => import('../components/Tickets/Cancel/steps/Step1.vue')
            },
            {
                path: '/tickets-anular-2',
                name: 'tickets-anular-2',
                component: () => import('../components/Tickets/Cancel/steps/Step2.vue')
            },
            // Buscar
            {
                path: '/tickets-buscar-1',
                name: 'tickets-buscar-1',
                component: () => import('../components/Tickets/Search/steps/Step1.vue')
            },
            {
                path: '/tickets-buscar-2',
                name: 'tickets-buscar-2',
                component: () => import('../components/Tickets/Search/steps/Step2.vue')
            },
            {
                path: '/tickets-buscar-3',
                name: 'tickets-buscar-3',
                component: () => import('../components/Tickets/Search/steps/Step3.vue')
            },
            // Reportes
            {
                path: '/reportes-venta',
                name: 'reportes-venta',
                component: () => import('../components/Reports/Sale.vue')
            },
            {
                path: '/reportes-combinado',
                name: 'reportes-combinado',
                component: () => import('../components/Reports/Combined.vue')
            },
            {
                path: '/reportes-conalot',
                name: 'reportes-conalot',
                component: () => import('../components/Reports/Conalot.vue')
            },
            // Usuarios
            {
                path: '/usuarios',
                name: 'usuarios',
                component: () => import('../components/Settings/Users/Main.vue')
            },
            {
                path: '/usuarios/agregar-1',
                name: 'usuarios/agregar-1',
                component: () => import('../components/Settings/Users/Add/Step1.vue')
            },
            {
                path: '/usuarios/agregar-2',
                name: 'usuarios/agregar-2',
                component: () => import('../components/Settings/Users/Add/Step2.vue')
            },
            // Usuarios Conalot
            {
                path: '/usuarios-conalot',
                name: 'usuarios-conalot',
                component: () => import('../components/Settings/Users-Conalot/Main.vue')
            },
            {
                path: '/usuarios-conalot/agregar-1',
                name: 'usuarios-conalot/agregar-1',
                component: () => import('../components/Settings/Users-Conalot/Add/Step1.vue')
            },
            {
                path: '/usuarios-conalot/agregar-2',
                name: 'usuarios-conalot/agregar-2',
                component: () => import('../components/Settings/Users-Conalot/Add/Step2.vue')
            },
            {
                path: '/agencias',
                name: 'agencias',
                component: () => import('../components/Settings/Agencies/Main.vue')
            },
            // Conalot
            {
                path: '/conalot',
                name: '/conalot',
                component: () => import('../components/Settings/Conalot/Main.vue')
            },
            // Agencias
            {
                path: '/agencias/agregar-1',
                name: 'agencias/agregar-1',
                component: () => import('../components/Settings/Agencies/Add/Step1.vue')
            },
            {
                path: '/agencias/agregar-2',
                name: 'agencias/agregar-2',
                component: () => import('../components/Settings/Agencies/Add/Step2.vue')
            },
            {
                path: '/agencias/agregar-3',
                name: 'agencias/agregar-3',
                component: () => import('../components/Settings/Agencies/Add/Step3.vue')
            },
            {
                path: '/agencias/agregar-4',
                name: 'agencias/agregar-4',
                component: () => import('../components/Settings/Agencies/Add/Step4.vue')
            },
            // Distribuidores
            {
                path: '/distribuidores',
                name: 'distribuidores',
                component: () => import('../components/Settings/Distributors/Main.vue')
            },
            {
                path: '/distribuidores/agregar-1',
                name: 'distribuidores/agregar-1',
                component: () => import('../components/Settings/Distributors/Add/Step1.vue')
            },
            {
                path: '/distribuidores/agregar-2',
                name: 'distribuidores/agregar-2',
                component: () => import('../components/Settings/Distributors/Add/Step2.vue')
            },
            {
                path: '/distribuidores/agregar-3',
                name: 'distribuidores/agregar-3',
                component: () => import('../components/Settings/Distributors/Add/Step3.vue')
            },
            {
                path: '/distribuidores/agregar-4',
                name: 'distribuidores/agregar-4',
                component: () => import('../components/Settings/Distributors/Add/Step4.vue')
            },
            // Bancas
            {
                path: '/bancas',
                name: 'bancas',
                component: () => import('../components/Settings/Bankings/Main.vue')
            },
            {
                path: '/bancas/agregar-1',
                name: 'bancas/agregar-1',
                component: () => import('../components/Settings/Bankings/Add/Step1.vue')
            },
            {
                path: '/bancas/agregar-2',
                name: 'bancas/agregar-2',
                component: () => import('../components/Settings/Bankings/Add/Step2.vue')
            },
            {
                path: '/bancas/agregar-3',
                name: 'bancas/agregar-3',
                component: () => import('../components/Settings/Bankings/Add/Step3.vue')
            },
            {
                path: '/bancas/agregar-4',
                name: 'bancas/agregar-4',
                component: () => import('../components/Settings/Bankings/Add/Step4.vue')
            },
            // Bancas
            {
                path: '/bloques',
                name: 'bloques',
                component: () => import('../components/Settings/Blocks/Main.vue')
            },
            {
                path: '/bloques/agregar-1',
                name: 'bloques/agregar-1',
                component: () => import('../components/Settings/Blocks/Add/Step1.vue')
            },
            {
                path: '/bloques/agregar-2',
                name: 'bloques/agregar-2',
                component: () => import('../components/Settings/Blocks/Add/Step2.vue')
            },
            {
                path: '/bloques/agregar-3',
                name: 'bloques/agregar-3',
                component: () => import('../components/Settings/Blocks/Add/Step3.vue')
            },
            // Bugs
            {
                path: '/errores-sistema',
                name: 'errores-sistema',
                component: () => import('../pages/bugs/System.vue')
            },
            {
                path: '/errores-configuracion',
                name: 'errores-configuracion',
                component: () => import('../pages/bugs/Settings.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/auth/Login.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('../pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('../pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('../pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('../pages/Access.vue')
    },


];

const index = createRouter({
    history: createWebHashHistory(),
    routes,
});

index.beforeEach((to, from, next) => {

    if (to.path !== '/login' && !localStorage.getItem('token')) {

        next({path: '/login'})
    } else {

        jwt.verify(localStorage.getItem('token'), 'wepa', function (err) {
            if (err) {
                localStorage.removeItem('token');
                localStorage.removeItem('vuex');
                // alert('Su sesión ha expirado.')

            }
        });

        if (!checkPermissions(to.path)) {
            localStorage.removeItem('token');
            localStorage.removeItem('vuex');
            next({path: '/login'})
        } else {

            next()
        }
    }
},)

function checkPermissions(path) {
    let response = true;
    if (store.getters.getProfile) {
        switch (Number(store.getters.getProfile.role_id)) {
            case 7: // Analista
                if (path === '/agencias' ||
                    path === '/usuarios' ||
                    path === '/listas-online' ||
                    path === '/ventas-online'
                ) {
                    response = false;
                }
                break;
            case 1:


        }
    }
    return response;
}

export default index;

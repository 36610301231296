<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle"/>
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menuAnalista" @menuitem-click="onMenuItemClick" v-if="identity.roleId===7"/>
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" v-if="identity.roleId!==7"/>
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view/>
      </div>
      <AppFooter/>
    </div>

    <!--    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange"/>-->
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './app/AppTopbar.vue';
import AppMenu from './app/AppMenu.vue';
// import AppConfig from './AppConfig.vue';
import AppFooter from './app/AppFooter.vue';
import VueCookies from "vue-cookies";
import EventBus from "@/app/AppEventBus";

export default {
  created() {
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  mounted() {
    const theme = !VueCookies.get('dark') ? 'bootstrap4-light-green' : 'bootstrap4-dark-green'
    EventBus.emit('theme-change', {theme: theme, dark: null});
    // Push config menu
    if (this.identity.roleId < 4) {

      this.menu[2].items.push(
          this.getUsers()
      );
    }
    this.menu[2].items.push(
        this.getEntities()
    );


    if (this.identity.roleId === 1 && VueCookies.get('currency') !== 'USD') {
      this.menu.push(
          this.getConalot()
      );
    }
  },
  emits: ['change-theme'],
  data() {
    return {
      VUE_BASE_URL: 'TEST',
      BASE_URL: 'TEST',
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: true,
      menu: [
        {
          items: [{
            label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
          }]
        },
        {
          label: 'Sistema', icon: 'pi pi-fw pi-search',
          items: [
            {
              label: 'Ventas', icon: 'pi pi-shopping-cart',
              items: [
                {
                  label: 'Ventas en línea', icon: 'pi pi-chart-bar', to: '/ventas-online',
                },
                {
                  label: 'Listas en línea', icon: 'pi pi-file', to: '/listas-online',
                },
              ]
            },
            {
              label: 'Tickets', icon: 'pi pi-ticket',
              items: [
                {
                  label: 'Buscar', icon: 'pi pi-search', to: '/tickets-buscar-1',
                },
                {
                  label: 'Anular', icon: 'pi pi-times', to: '/tickets-anular-1',
                },
                {
                  label: 'Ganadores', icon: 'pi pi-wallet', to: '/tickets-ganadores',
                },
                {
                  label: 'Pagados', icon: 'pi pi-money-bill', to: '/tickets-pagados',
                },
                {
                  label: 'Anulados', icon: 'pi pi-trash', to: '/tickets-anulados',
                },
                {
                  label: 'Vendidos', icon: 'pi pi-shopping-bag', to: '/tickets-vendidos',
                },
              ],
            },
            {
              label: 'Reportes', icon: 'pi pi-file',
              items: [
                {
                  label: 'Ventas', icon: 'pi pi-file', to: '/reportes-venta',
                },
                {
                  label: 'Combinado', icon: 'pi pi-file', to: '/reportes-combinado',
                },
                // {
                //   label: 'Conalot', icon: 'pi pi-file', to: '/reportes-conalot',
                // },
              ],
            },
          ]
        },
        {
          label: 'Configuración',
          items: []
        }
      ],
      menuAnalista: [
        {
          items: [{
            label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
          }]
        },
        {
          label: 'Sistema', icon: 'pi pi-fw pi-search',
          items: [
            {
              label: 'Tickets', icon: 'pi pi-ticket',
              items: [
                {
                  label: 'Ganadores', icon: 'pi pi-wallet', to: '/tickets-ganadores',
                },
                {
                  label: 'Pagados', icon: 'pi pi-money-bill', to: '/tickets-pagados',
                },
                {
                  label: 'Anulados', icon: 'pi pi-trash', to: '/tickets-anulados',
                },
              ],
            },
            {
              label: 'Reportes', icon: 'pi pi-file',
              items: [
                {
                  label: 'Ventas', icon: 'pi pi-file', to: '/reportes-venta',
                },
                {
                  label: 'Combinado', icon: 'pi pi-file', to: '/reportes-combinado',
                },
              ],
            },
          ]
        },
      ],
      identity: null,
      entidades: null,
      bugs: null,
    }
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    },
    getEntities() {
      let items = [
        {
          label: 'Agencias', icon: 'pi pi-fw pi-print', to: '/agencias',
        },
      ];
      // Distribuidores
      if (this.identity.roleId < 4 || this.identity.roleId === null) {
        items.push(
            {
              label: 'Distribuidores', icon: 'pi pi-fw pi-server', to: '/distribuidores',
            },
        );
      }
      // Bancas
      if (this.identity.roleId < 3 || this.identity.roleId === null) {
        items.push(
            {
              label: 'Bancas', icon: 'pi pi-fw pi-book', to: '/bancas',
            },
        );
      }
      // Bloques
      if (this.identity.roleId < 2) {
        items.push(
            {
              label: 'Bloques', icon: 'pi pi-fw pi-building', to: '/bloques',
            }
        );
      }
      return {
        label: 'Entidades', icon: 'pi pi-fw pi-sitemap',
        items: items
      };
    },
    getConalot() {
      return {
        label: "Conalot",
        items: [
          {
            label: "Usuarios", "icon": "pi pi-users", "to": "/usuarios-conalot"
          },
          {
            label: "Entidades", "icon": "pi pi-fw pi-sitemap", "to": "/conalot"
          },
          {
            label: "Reporte", "icon": "pi pi-file", "to": "/reportes-conalot"
          },
        ]
      }


    },
    getUsers() {
      return {
        label: 'Usuarios', icon: 'pi pi-fw pi-users', to: '/usuarios',
      }
    }
  },

  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    logo() {
      return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'AppConfig': AppConfig,
    'AppFooter': AppFooter,
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>

import dotenv from 'dotenv';
import axios from "axios";

class BaseService {
    constructor() {
        // Get environment variables
        dotenv.config()
        this.axios = axios;
        // Select currency API
        if (localStorage.getItem('currency') === 'USD') {
            this.axios.defaults.baseURL = window._env.VUE_APP_API_USD_URL ? window._env.VUE_APP_API_USD_URL : process.env.VUE_APP_API_USD_URL;
        } else {
            this.axios.defaults.baseURL = window._env.VUE_APP_API_URL ? window._env.VUE_APP_API_URL : process.env.VUE_APP_API_URL;
        }

        // this.axios.defaults.headers.common['Authorization'] = store.getState().session.token;
        this.axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    }

}

export default BaseService;

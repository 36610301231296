<template>
  <div class="layout-footer">
    <img alt="Logo" :src="footerImage()" height="20" class="mr-2"/>

    <span class="font-medium ml-2">
      {{ dateAndTime }}
    </span>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "AppFooter",
  mounted: function () {
    this.dateAndTime = this.getDateAndTime();

    window.setInterval(() => {
      this.dateAndTime = this.getDateAndTime();
    }, 30000)
  },
  data() {
    return {
      date: null,
      mainService: null,
      dateAndTime: null,
    }
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme ? 'images/logo.png' : 'images/logo.png';
    },
    getDateAndTime() {
      return moment().format('DD/MM/YYYY hh:mm A');
    }

  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  }
}
</script>